import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Reliable software offering in production – Step 4A of our approach"
        description="Once a software product has been launched it’s essential to ensure continuous, secure and correct operation."
        ogImage="serving-en.png"
        translatedPagePath="/nl/werkwijze/service/"
        locale={locale}>
        <Link
            to="/en/approach/product-realisation/"
            className="body__nav body__nav--left"
            title="Approach step 3. Product realisation">
            Previous step
        </Link>

        <Link
            to="/en/approach/continuous-development/"
            className="body__nav body__nav--right"
            title="Approach step 4B. Continuous development">
            Next step
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Serving <br /> software <br /> reliably
                        </h2>
                        <div className="display__number">4a</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Once a software product has been launched it’s essential to ensure continuous, secure and
                            correct operation, which doesn’t happen by itself. So we decide together what level of
                            service and maintenance is most appropriate for the product.
                        </p>
                        <p>
                            We are available to provide <span className="highlight">support</span> for our client and to
                            resolve <span className="highlight">urgent issues</span>. We also keep the application
                            infrastructure <span className="highlight">up to date</span>. We record these arrangements
                            in a service agreement.
                        </p>
                        <p>
                            For business critical software products, we offer an SLA, with guaranteed uptime and data
                            integrity. We provide <span className="highlight">continuous monitoring</span> and{' '}
                            <span className="highlight">intervene proactively</span> when problems arise.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Ongoing maintenance</li>
                            <li className="ion-md-arrow-dropright">Proactive intervention</li>
                            <li className="ion-md-arrow-dropright">Continuous monitoring</li>
                            <li className="ion-md-arrow-dropright">Uptime guaranteed</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/en/approach/continuous-development/"
                            name="At the same time: continuous development"
                            title="Approach step 4B. continuous development"
                        />
                    </div>
                </div>
            </article>

            <article className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Our <br /> infra&shy;structure <br /> expertise
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Providing software products reliably is dependent on the chosen infra&shy;structure
                            technology.
                        </p>
                        <p>
                            With our extensive experience in realising different software products, we use the right
                            infras&shy;tructure technology for your software product. We examine the specific use case
                            and make a well-thought-out choice between serverless, cloud native, platform-as-a-service
                            and classic virtuali&shy;sation.
                        </p>
                        <p>
                            We connect secondary infras&shy;tructure components correctly. For example, services for
                            transactional email, file storage and dealing with real time communi&shy;cation. For
                            software products with an SLA, we use the right monitoring tools to keep a constant watch.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />
                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo AWS" fluid={data.aws.childImageSharp.fluid} />
                                <span>Amazon Web Services</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Google cloud platform" fluid={data.googleCloud.childImageSharp.fluid} />
                                <span>Google Cloud Platform</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Digital Ocean" fluid={data.digitalocean.childImageSharp.fluid} />
                                <span>Digital Ocean</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Heroku" fluid={data.heroku.childImageSharp.fluid} />
                                <span>Heroku</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo AWS Lambda" fluid={data.awsLambda.childImageSharp.fluid} />
                                <span>AWS Lambda</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Firebase" fluid={data.firebase.childImageSharp.fluid} />
                                <span>Firebase</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Postmark" fluid={data.postmark.childImageSharp.fluid} />
                                <span>Postmark</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Solarwinds" fluid={data.solarwinds.childImageSharp.fluid} />
                                <span>Solarwinds</span>
                            </div>
                        </div>
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} locale={locale} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        aws: file(relativePath: {eq: "logos/aws.png"}) {
            ...logoImage
        }
        googleCloud: file(relativePath: {eq: "logos/google-cloud.png"}) {
            ...logoImage
        }
        digitalocean: file(relativePath: {eq: "logos/digitalocean.png"}) {
            ...logoImage
        }
        heroku: file(relativePath: {eq: "logos/heroku.png"}) {
            ...logoImage
        }
        awsLambda: file(relativePath: {eq: "logos/aws-lambda.png"}) {
            ...logoImage
        }
        firebase: file(relativePath: {eq: "logos/firebase.png"}) {
            ...logoImage
        }
        postmark: file(relativePath: {eq: "logos/postmark.png"}) {
            ...logoImage
        }
        solarwinds: file(relativePath: {eq: "logos/solarwinds.png"}) {
            ...logoImage
        }
    }
`;
